<script setup>
const props = defineProps(["singleBible", "items"]);
const selectedTab = ref("books");
const selectedBook = ref(null);

function handleChangedBook({ book }) {
  selectedTab.value = "chapters";
  selectedBook.value = book;
}

function handleChangedChapter({ chapterNum }) {
  useEvent("singlebible.selectChapter", {
    singleBible: props.singleBible,
    bookCode: selectedBook.value?.bookCode,
    chapterNum,
  });

  selectedTab.value = "books";
  selectedBook.value = "";
}

function handleBeforeOpen() {
  selectedTab.value = "books";
}
</script>

<template>
  <v-menu>
    <template #activator="{ on }">
      <v-btn
        @click="
          handleBeforeOpen();
          on();
        "
        class="select-button"
      >
        {{
          singleBible?.location.value?.bookModel?.bookNameCanonLong ||
          transl("Select book")
        }}
        <img class="chevron" src="/icons/chevron-down.svg" alt="" />
      </v-btn>
    </template>

    <template #default="{ close }">
      <div class="tabs">
        <div class="tabs-slider">
          <div class="books-el" :class="{ active: selectedTab == 'books' }">
            <VBooksList
              :items="items"
              :singleBible="singleBible"
              @change="handleChangedBook($event)"
            ></VBooksList>
          </div>
          <div
            class="chapters-el"
            :class="{ active: selectedTab == 'chapters' }"
          >
            <div class="d-flex px-3 menu-header">
              <v-btn @click="selectedTab = 'books'" class="back-button my-2">
                <!-- <mdicon name="arrow-left" class="icon ml-auto" /> -->
              </v-btn>
              <span class="ml-2">{{ selectedBook?.bookNameCanonLong }}</span>
            </div>
            <VChaptersList
              :items="selectedBook?.bookChapters"
              :singleBible="singleBible"
              @change="
                handleChangedChapter($event);
                close();
              "
              class="mb-4 ml-4"
            ></VChaptersList>
          </div>
        </div>
      </div>
    </template>
  </v-menu>
</template>

<style scoped>
.chevron {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 7px;
  top: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}
.select-button {
  border-radius: 6px;
  background-color: var(--root-white);
  width: 100%;
  color: black;
  padding: 0px 4px 0px 8px;
  justify-content: flex-start;
  box-shadow: 0px 4px 11px 0px #e1e5f1;
  height: 40px;
  align-items: flex-start;
  align-items: center;
  box-sizing: border-box;
}

.select-button {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  padding-right: calc(10% + 12px);
  position: relative;
  line-height: 40px;
}
.more {
  position: absolute;
  top: 9px;
  right: 3px;
}
.back-button {
  display: inline-flex;
}
.icon {
  min-height: 24px;
  min-width: 24px;
  align-items: flex-start;
}
.tabs {
  display: flex;
  transition: all 0.2s ease;
  position: relative;
  /* overflow: hidden; */
}

.tabs-slider {
  display: flex;
  /* position: absolute; */
  /* left: 0; */
  /* top: 0; */
  transition: all 0.2s ease;
}

.books-el {
  transition: all 0.2s ease;
  transform: translate3d(-50%, 0, 0);
  height: 0;
  width: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}

.books-el.active {
  transform: translate3d(0, 0, 0);
  height: unset;
  width: unset;
  opacity: 1;
  visibility: visible;
}

.chapters-el {
  transition: all 0.2s ease;
  transform: translate3d(60%, 0, 0);
  height: 0;
  width: 0;
  opacity: 0;
  visibility: hidden;
}

.chapters-el.active {
  transform: translate3d(0, 0, 0);
  height: unset;
  width: unset;
  opacity: 1;
  visibility: visible;
}

.menu-header {
  line-height: 50px;
}
</style>
